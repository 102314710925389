import React from "react";

import Layout from "../layout/index";

import Seo from "../components/seo/index";

import Racebreadcrumbpages from "../components/raceinfo/racebreadcrumb";

import Racemap  from "../components/raceinfo/raceinfoblock";

import Routemap  from "../components/raceinfo/routemap";

import Prizemoney  from "../components/raceinfo/prizemoney";

import Buttonevents  from "../components/raceinfo/buttonevents";

import Socialiconright from "../components/home/social-icon-right";

const Raceinfo = () => {
  const navigatetopage=(data)=>{
    console.log(data);
  }
  return (
    <div>
      <Seo title="Green Heartfulness Run 2024 - Race info" />
      <Layout isSticky>
        <Buttonevents  navigatetopage={navigatetopage}/>
        <Racebreadcrumbpages />
        <Socialiconright />
        <section id="race">
        <Racemap  />  
       </section>
      {/*   <section id="routemap">
        <Routemap  />
        </section>
        <section id="prizemoney">
        <Prizemoney />
  </section> */}
     
      </Layout>
    </div>
  );
};

export default Raceinfo;
