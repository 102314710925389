import { Link, navigate } from "gatsby";
import React, { useEffect } from "react";

const Buttonevents = ({navigatetopage}) => {

    const scrollToSection = () => {
        document.querySelectorAll("a.scroller").forEach((a) => {
          a.addEventListener("click", (event) => {
            if (event.target.getAttribute("href")) {
              event.preventDefault();
              const gotoSection = () => {
                const hash = event.target.getAttribute("href");
                const hashSection = hash.split("/#").pop();
                const headerHeight = 60;
                const scrollTarget = document.querySelector(`#${hashSection}`);
                if (scrollTarget) {
                  window.scrollTo({
                    top: scrollTarget.offsetTop - headerHeight,
                    behavior: "smooth",
                  });
                }
              };
              const scrollRootTarget = event.target.getAttribute("data-rootlink");
              if (scrollRootTarget) {
                navigate(scrollRootTarget);
                gotoSection();
              } else {
                gotoSection();
              }
            }
          });
        });
      };
    
      useEffect(() => {
        scrollToSection();
      }, []);

  return (
    <div className="buttonevents-wrapper">
      <div className="row">
        <div className="col-md-12">

            <div className="btn-events">
                <a href="#race" className="btn btn-blue scroller" type="button" rel="noreferrer"
              
                        as={Link}
                        to="/race"
                      >
                        <span
                          data-rootlink="/race"
                          id="horticultre"
                          className="header-link"
                        >Race Info
                        </span>
                </a>

                {/* <a className="btn btn-grey" type="button" href="#routemap" rel='noreferrer'>
                Route Map
                </a> */}

                {/* <a className="btn btn-grey" type="button" href="#prizemoney" rel='noreferrer'>
                Prize money
                </a> */}

                <a className="btn btn-grey" type="button" href="/faq" target="_parent" rel='noreferrer'>
                FAQs
                </a>

                <a className="btn btn-grey" type="button" href="/terms" target="_parent" rel='noreferrer'>
                Terms &amp; Conditions
                </a>

            </div>
        </div>
      </div>
    </div>
  );
};

export default Buttonevents;
