import React from "react";

import pathhead from "../../assets/images/raceinfo/path.png";

import gross from "../../assets/images/raceinfo/gross.png";

import routemapicon from "../../assets/images/raceinfo/routemap-icon.png";

import tshirt from "../../assets/images/raceinfo/t-shirt.png";

import medal from "../../assets/images/raceinfo/medal.png";
import clock from "../../assets/images/raceinfo/clock.png";

import breakfast from "../../assets/images/raceinfo/breakfast.png";

import ecertificate from "../../assets/images/raceinfo/certificate.png";

const Raceinfo = () => {
  return (
    <div className="raceinfo-wrapper subpages pt-4 pb-4">
      <div className="container-fluid">
        <div className="row mt-2" style={{ textAlign: "center" }}>
          <h3 className="title-heading"> RACE INFO </h3>
        </div>

        <div className="grid-container mb-4">
           <div className="grid-item">
            <div className="road-arch-block">
              <span className="top-head">
                <img className="img-fluid" src={pathhead} alt="Before" />
              </span>
              <div className="white-arch">
                <h3 className="count">
                  2<span className="less-item">KM </span>
                </h3>
                {/*<div className="route-map">
                  <span className="routemap-icon-wr">
                    <img
                      className="img-fluid"
                      src={routemapicon}
                      alt="routemapicon"
                    />{" "}
                  </span>
                  <h5 className="roadmap-text"> Route Map</h5>
  </div>*/}
   <p  style={{ marginTop:"5px",fontSize: "20px", textAlign: "center" }}> 
                  {" "}
                  <b> Family Run</b>
                </p>
                <p className="note-text pt-1">
                  {" "}
                  <b> Amount </b> - Rs. 500
                </p>
                <p className="note-text">
                  {" "}
                  <b> Includes:</b>
                </p>
                <div className="dis-icon">
                  <span>
                    {" "}
                    <img className="img-fluid" src={tshirt} alt="tshirt" />{" "}
                  </span>
                  <span>
                    {" "}
                    <img className="img-fluid" src={medal} alt="tshirt" />{" "}
                  </span>
                  <span className="tshirt-img-container">
                    {" "}
                    <img
                      className="img-fluid"
                      src={breakfast}
                      alt="tshirt"
                    />{" "}
                  </span>
                </div>
                <p className="note-content">
                T-shirt, Medal, Breakfast
                </p>
                
                <p className="note-content">
                  For minors, prior consent<br></br> of parent necessary.
                </p>
              </div>
            </div>
          </div>

          <div className="grid-item">
            <div className="road-arch-block">
              <span className="top-head">
                <img className="img-fluid" src={pathhead} alt="Before" />
              </span>
              <div className="white-arch">
                <h3 className="count">
                  5<span className="less-item">KM </span> 
                
                </h3>
                {/* <div className="route-map">
                  <span className="routemap-icon-wr">
                    {" "}
                    <img
                      className="img-fluid"
                      src={routemapicon}
                      alt="routemapicon"
                    />{" "}
                  </span>
                  <h5 className="roadmap-text"> Route Map</h5>
</div> */}
<p  style={{ marginTop:"5px",fontSize: "20px", textAlign: "center" }}> 
                  {" "}
                  <b> Timed Run </b>
                  {""}<img className="img-fluid" width={22} src={clock} alt="Clock" />{" "}
                </p>
                <p className="note-text pt-1">
                  {" "}
                  <b> Amount </b> - Rs. 600
                </p>
                <p className="note-text">
                  {" "}
                  <b> Includes: </b>
                </p>
                <div className="dis-icon">
                  <span style={{ marginLeft: "10px" }}>
                    {" "}
                    <img
                      className="img-fluid"
                      src={ecertificate}
                      alt="tshirt"
                    />{" "}
                  </span>
                  <span>
                    {" "}
                    <img className="img-fluid" src={tshirt} alt="tshirt" />{" "}
                  </span>
                  <span>
                    {" "}
                    <img className="img-fluid" src={medal} alt="tshirt" />{" "}
                  </span>
                  <span>
                    {" "}
                    <img
                      className="img-fluid"
                      src={breakfast}
                      alt="tshirt"
                    />{" "}
                  </span>
                </div>
                <p className="note-content">
                e-Certificate, T-shirt, Medal, Breakfast
                </p>
                <p className="note-content"> Age 8+ years on event day.</p>
              </div>
            </div>
          </div>

          <div className="grid-item">
            <div className="road-arch-block">
              <span className="top-head">
                <img className="img-fluid" src={pathhead} alt="Before" />
              </span>
              <div className="white-arch">
                <h3 className="count">
                  10<span className="less-item">KM </span>
                </h3>
               {/*  <div className="route-map">
                  <span className="routemap-icon-wr">
                    {" "}
                    <img
                      className="img-fluid"
                      src={routemapicon}
                      alt="routemapicon"
                    />{" "}
                  </span>
                  <h5 className="roadmap-text"> Route Map</h5>
</div> */}
<p style={{ marginTop:"5px",fontSize: "20px", textAlign: "center" }}> 
                  {" "}
                  <b> Timed Run </b>
                  {""}<img className="img-fluid" width={22} src={clock} alt="Clock" />{" "}
                </p>
                <p className="note-text pt-1">
                  {" "}
                  <b> Amount </b> - Rs. 700
                </p>
                <p className="note-text">
                  {" "}
                  <b> Includes: </b>
                </p>
                <div className="dis-icon">
                  <span style={{ marginLeft: "10px" }}>
                    {" "}
                    <img
                      className="img-fluid"
                      src={ecertificate}
                      alt="tshirt"
                    />{" "}
                  </span>
                  <span>
                    {" "}
                    <img className="img-fluid" src={tshirt} alt="tshirt" />{" "}
                  </span>
                  <span>
                    {" "}
                    <img className="img-fluid" src={medal} alt="tshirt" />{" "}
                  </span>
                  <span>
                    {" "}
                    <img
                      className="img-fluid"
                      src={breakfast}
                      alt="tshirt"
                    />{" "}
                  </span>
                </div>
                <p className="note-content">
                e-Certificate, T-shirt, Medal, Breakfast
                </p>
                <p className="note-content"> Age 15+ years on event day. </p>
              </div>
            </div>
          </div>

          <div className="grid-item">
            <div className="road-arch-block">
              <span className="top-head">
                <img className="img-fluid" src={pathhead} alt="Before" />
              </span>
              <div className="white-arch">
                <h3 className="count">
                  21<span className="less-item">KM </span>
                </h3>
                {/* <div className="route-map">
                  <span className="routemap-icon-wr">
                    {" "}
                    <img
                      className="img-fluid"
                      src={routemapicon}
                      alt="routemapicon"
                    />{" "}
                  </span>
                  <h5 className="roadmap-text"> Route Map</h5>
                </div>*/}
                <p  style={{ marginTop:"5px",fontSize: "20px", textAlign: "center" }}> 
                  {" "}
                  <b> Timed Run </b>
                  {""}<img className="img-fluid" width={22} src={clock} alt="Clock" />{" "}
                </p>
                <p className="note-text pt-1">
                  {" "}
                  <b> Amount </b> - Rs. 800
                </p>
                <p className="note-text">
                  {" "}
                  <b> Includes: </b>
                </p>
                <div className="dis-icon">
                  <span style={{ marginLeft: "10px" }}>
                    {" "}
                    <img
                      className="img-fluid"
                      src={ecertificate}
                      alt="tshirt"
                    />{" "}
                  </span>
                  <span>
                    {" "}
                    <img className="img-fluid" src={tshirt} alt="tshirt" />{" "}
                  </span>
                  <span>
                    {" "}
                    <img className="img-fluid" src={medal} alt="tshirt" />{" "}
                  </span>
                  <span>
                    {" "}
                    <img
                      className="img-fluid"
                      src={breakfast}
                      alt="tshirt"
                    />{" "}
                  </span>
                </div>
                <p className="note-content">
                e-Certificate, T-shirt, Medal, Breakfast
                </p>
                <p className="note-content"> Age 18+ years on event day. </p>
              </div>
            </div>
          </div>

          <div className="grid-item">
            <div className="road-arch-block">
              <span className="top-head">
                <img className="img-fluid" src={pathhead} alt="Before" />
              </span>
              <div className="white-arch ">
                <h3 className="virtual_run_txt">
                  {" "}
                  <span className="less-item" style={{ marginLeft: "8%" }}>
                    {" "}
                    Global Run{" "}
                  </span>
                </h3>
               {/*  <div className="route-map">
                  <span className="routemap-icon-wr">
                    {" "}
                    <img
                      className="img-fluid"
                      src={routemapicon}
                      alt="routemapicon"
                    />{" "}
                  </span>
                  <h5 className="roadmap-text"> Route Map</h5>
              </div>*/}
            
              <p style={{ marginTop:"5px",fontSize: "20px", textAlign: "center" }}> 
                  {" "}
                  <b> Run from anywhere</b>
                </p>
                <p className="note-text" style={{ display: "flex" }}>
                  {" "}
                  <b> Rs. 99 &nbsp; : </b>
                  <div className="dis-icon ml-1 p-0">
                    <span>
                      {" "}
                      <img
                        className="img-fluid"
                        src={ecertificate}
                        alt="tshirt"
                      />{" "}
                    </span>
                  </div>{" "}
                </p>
                <p className="note-content">
                e-Certificate
                </p>
                <p className="note-text" style={{ display: "flex" }}>
                  {" "}
                  <b> Rs. 399 : </b>
                  <div className="dis-icon ml-1 p-0">
                    <span style={{ marginLeft: "4px" }}>
                      {" "}
                      <img
                        className="img-fluid"
                        src={ecertificate}
                        alt="ecerticate1"
                      />{" "}
                    </span>
                    <span>
                      {" "}
                      <img
                        className="img-fluid"
                        src={medal}
                        alt="tshirt"
                      />{" "}
                    </span>
                  </div>{" "}
                </p>
                <p className="note-content">
                e-Certificate, Medal
                </p>
                <p className="note-text" style={{ display: "flex" }}>
                  {" "}
                  <b> Rs. 599 : </b>
                  <div className="dis-icon ml-1 p-0">
                    <span>
                      {" "}
                      <img
                        className="img-fluid"
                        src={ecertificate}
                        alt="tshirt"
                      />{" "}
                    </span>
                    <span>
                      {" "}
                      <img
                        className="img-fluid"
                        src={medal}
                        alt="tshirt"
                      />{" "}
                    </span>
                    <span>
                      {" "}
                      <img
                        className="img-fluid"
                        src={tshirt}
                        alt="tshirt"
                      />{" "}
                    </span>
                  </div>
                </p>
                <p className="note-content">
                e-Certificate, Medal, T-shirt
                </p>
                <p className="note-content">
                  {" "}
                  For runners enthusiast <br></br>in other locations
                </p>
              </div>
            </div>
          </div> 

          {/* <div className="col-md-1"></div> */}
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <div
              className="note-block-wrapper"
              style={{ justifyContent: "center" }}
            >
              <div>
                <b style={{ fontSize: "20px", marginRight: "15px" }}>Note</b>
              </div>
              <div>
                <p style={{ marginBottom: "5px" }}>
                  <b>
                  <span class="dots-big">1.</span> For group registrations i.e.
                  20 or more please contact on <a href="mailto:registrations@greenheartfulnessrun.com">registrations@greenheartfulnessrun.com</a>
                  </b>
                </p>

                <p style={{ marginBottom: "5px" }}>
                <b>
                  <span class="dots-big">2.</span> BIBs will not be distributed
                  on the race day. Please collect it on the BIB distribution day
                  </b>
                </p>
                <p style={{marginBottom: '5px'}}>
                <b>
              <span class="dots-big">3.</span>  Please provide your unique runner ID during BIB collection
              </b>
              </p>
                <p>

     <b>           
     <span class="dots-big">4.</span> Global Run participants will get a participation certificate (without any km)
          </b>    
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="gross-top-set">
            <img className="img-fluid" src={gross} alt="gross" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Raceinfo;
