import React from "react";

import facebook from "../../assets/images/footer/facebook.png";

// import twitter from "../../assets/images/footer/twitter.png";

import youtube from "../../assets/images/footer/youtube.png";

import instagram from "../../assets/images/footer/instagram.png";

export default function Socialiconright() {
  return (
    <div className="social-icon-right-wrapper">
      <ul className="icon-bar">
        <li>
          <a
            style={{ padding: "0px" }}
            className="btn"
            href="https://www.facebook.com/greenkanharun"
            target="_blank"
            rel="noreferrer"
          >
            <img src={facebook} alt="facebook" />
          </a>
        </li>
        {/* <li>
          <a
            style={{ padding: "0px" }}
            className="btn"
            href=""
            target="_blank"
            rel="noreferrer"
          >
            <img src={twitter} alt="twitter" />
          </a>
        </li> */}
        <li>
          <a
            style={{ padding: "0px" }}
            className="btn"
            href="https://youtu.be/ZujB55bLhnk"
            target="_blank"
            rel="noreferrer"
          >
            <img src={youtube} alt="youtube" />
          </a>
        </li>
        <li>
          <a
            style={{ padding: "0px" }}
            className="btn"
            href="https://www.instagram.com/greenkanharun/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagram} alt="instagram" />
          </a>
        </li>
      </ul>
    </div>
  );
}
